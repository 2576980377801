<template>
  <div>
    <b-overlay
      :show="$apollo.loading || mutationLoading"
      rounded="sm"
    >
      <b-table
        :fields="['round', {key: 'title', thStyle: 'width: 60%'}, 'status','actions']"
        :items="rounds"
        borderless
        responsive
        show-empty
        striped
      >
        <template #cell(round)="row">
          {{ row.index+1 }}
        </template>
        <template #cell(title)="row">

          <b-form-input
            v-model="row.item.title"
            :readonly="row.item.status !== 'draft'"
            :state="row.item.title? null : false"
            placeholder="Round Title"
          />
        </template>
        <template #cell(status)="row">
          <b-badge
            v-if="!row.item.id"
            variant="light-danger"
          >
            Unsaved
          </b-badge>
          <b-badge
            v-else
            :variant="statusVariant(row.item.status)"
            style="text-transform: capitalize;"
          >
            {{ row.item.status }}
          </b-badge>

        </template>
        <template
          #cell(actions)="row"
        >
          <b-button-group
            size="sm"
            style="white-space: nowrap"
          >
            <b-button
              v-if="row.item.id && row.item.status != 'draft'"
              :to="{ name: 'application-question', params: { pid: $route.params.id, apid: row.item.id }, query: {readonly: 'true'} }"
              size="sm"
              variant="outline-primary"
            >
              <feather-icon icon="EyeIcon" />
              Preview
            </b-button>
            <b-button
              v-if="row.item.status === 'draft' && row.item.id"
              variant="secondary"
              @click="selectedRound = row.index; $bvModal.show('appModal')"
            >
              <feather-icon icon="Edit2Icon" />
              Edit Form
            </b-button>
            <b-button
              variant="outline-danger"
              @click="selectedRound = row.index; $bvModal.show('deleteModal')"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>

            <b-button
              v-if="row.item.status === 'draft' && !row.item.id"
              size="sm"
              variant="primary"
              @click="updateApplication(row.index)"
            >
              <feather-icon
                class="mr-25"
                icon="SaveIcon"
              />
              <span>Save</span>
            </b-button>
          </b-button-group>
        </template>
      </b-table>
    </b-overlay>
    <b-button
      v-if="rows.length<2"
      :disabled="!!rounds.length && rounds[rounds.length-1]['id'] === undefined"
      class="mt-1"
      variant="primary"
      @click="addRound()"
    >
      <feather-icon
        class="mr-25"
        icon="PlusIcon"
      />
      Add Round
    </b-button>
    <b-modal
      id="appModal"
      ok-only
      ok-title="Update"
      no-close-on-backdrop
      size="lg"
      title="Application Form"
      @ok="updateApplication(selectedRound)"
    >
      <form-builder
        v-if="selectedRound !== null"
        v-model="rounds[selectedRound].programs_applicationsquestionstables"
        :inherited-sections="rounds[selectedRound].programs_applicationsquestionstables"
      />
    </b-modal>
    <b-modal
      id="deleteModal"
      ok-title="Yes, Delete"
      ok-variant="danger"
      button-size="sm"
      no-close-on-backdrop
      centered="true"
      size="sm"
      title="Confirm Delete"
      @ok="deleteround()"
    >
      <h6>Are you sure you want to delete this round?</h6>
    </b-modal>
  </div>
</template>
<script>
import FormBuilder from '@/views/incubator-portal/programs/components/FormBuilder.vue'
import {
  BBadge,
  BButton,
  BButtonGroup,
  BFormInput,
  BModal,
  BTable,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
// import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BButton,
    BFormInput,
    BBadge,
    BModal,
    BTable,
    BButtonGroup,
    FormBuilder,

  },
  data() {
    return {
      mutationLoading: false,
      selectedRound: null,
      pageLength: 5,
      filter: {
        round: null,
        status: null,
      },
      columns: [
        {
          label: 'Applicant',
          field(row) {
            if (!row.users_organizationtable) return '-'
            return row.users_organizationtable.title
          },
        },
        {
          id: 'round',
          label: 'Shortlisting Round',
          field(row) {
            if (!row.programs_applicationformstable) return ''
            return row.programs_applicationformstable.title
          },
          filterOptions: {
            enabled: true, // enable filter for this column
            customFilter: true, // use custom filter component
          },
        },
        {
          label: 'Status',
          field: 'status',
          sortable: false,
          filterOptions: {
            enabled: true, // enable filter for this column
            customFilter: true, // use custom filter component
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      rounds: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        draft: 'light-primary',
        enabled: 'light-success',
        disabled: 'light-secondary',
        null: 'light-primary',
        accepted: 'light-success',
        declined: 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    filteredRows() {
      if (!this.filter.round && !this.filter.status) return this.rows
      return this.rows.filter(row => row.programs_applicationformstable)
        .filter(row => (this.filter.round === null || row.programs_applicationformstable.round_number === this.filter.round))
        .filter(row => (this.filter.status === null || !row.programs_applicationformstable.status === this.filter.status || (this.filter.status === 'in-process' && !row.programs_applicationformstable.status)))
    },
    roundFilterOptions() {
      return this.rows.filter(e => e.programs_applicationformstable)
        .sort((a, b) => a.programs_applicationformstable.round_number - b.programs_applicationformstable.round_number)
        .map(row => ({
          text: `Round ${row.programs_applicationformstable.round_number} - ${row.programs_applicationformstable.title}`,
          value: row.programs_applicationformstable.round_number,
        }))
        .filter((e, i, a) => a.findIndex(t => t.value === e.value) === i) // Remove duplicates
    },
  },
  methods: {
    addRound() {
      this.rounds.push({
        title: 'New Round',
        status: 'draft',
        review_status: 'draft',
        program_id: this.$route.params.id,
        programs_applicationsquestionstables: [],
      })
    },
    updateApplication(i) {
      const payload = this.rounds[i]

      if (payload.programs_applicationsquestionstables.length) {
        payload.programs_applicationsquestionstables = {
          data: payload.programs_applicationsquestionstables,
          on_conflict: {
            constraint: 'programs_applicationsquestionstable_pkey',
            update_columns: ['question', 'input_type', 'options', 'section'],
          },
        }
      } else {
        delete payload.programs_applicationsquestionstables
      }
      this.mutationLoading = true
      this.$apollo.mutate(({
        mutation: gql`mutation($object: programs_applicationformstable_insert_input!) {
            insert_programs_applicationformstable_one(object: $object, on_conflict: {constraint: programs_applicationformstable_pkey, update_columns: title}) {
              id
            }
          }`,
        variables: {
          object: payload,
        },
        update: () => {
          this.$apollo.queries.rounds.refetch()
          this.mutationLoading = false
        },
      }))
    },
    updateApplicationStatus(status, item, col) {
      const update = status === 'enabled' ? 'disabled' : 'enabled'
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation {
          update_programs_applicationformstable_by_pk(pk_columns: {id: ${item}}, _set: {${col}: ${update}}) {
            id
          }
        }`,
        update: () => {
          this.$apollo.queries.rounds.refetch()
          this.mutationLoading = false
        },
      })
    },
    deleteround() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation {
          update_programs_applicationformstable_by_pk(pk_columns: {id: ${this.rows[this.selectedRound].current_application_form_id}}, _set: {is_deleted: true}) {
            id
          }
        }`,
        update: () => {
          this.$apollo.queries.rounds.refetch()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_applicantstable(where:{program_id: {_eq: ${this.$route.params.id}}}){
            id
            program_id
            status
            current_application_form_id
            users_organizationtable {
              title
            }
            programs_applicationformstable {
              id
              title
              round_number
            }
          }
        }`
      },
      update: data => data.programs_applicantstable,
    },
    rounds: {
      query() {
        return gql`
        {
          programs_applicationformstable(where:{program_id: {_eq: ${this.$route.params.id}}}, order_by: {id: asc}){
            id
            title
            status
            review_status
            programs_applicationsquestionstables(order_by: {id: asc}) {
              id
              question
              section
              options
              input_type
              is_required
            }
          }
        }`
      },
      update(data) {
        /* eslint-disable no-param-reassign */
        if (data.programs_applicationformstable.length) {
          data.programs_applicationformstable.forEach(e => {
            if (e.status !== 'draft') {
              delete e.programs_applicationsquestionstables
            }
          })
        }
        return data.programs_applicationformstable
      },
    },
  },
}
</script>

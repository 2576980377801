<template>
  <div>
    <b-overlay
      :show="$apollo.loading || mutationLoading"
      rounded="sm"
    >
      <b-table
        :fields="['ID', 'rounds', { key: 'application', label: 'application form' }]"
        :items="rounds"
        borderless
        responsive
        show-empty
        striped
      >
        <template #cell(ID)="row">
          {{ row.index + 1 }}
        </template>
        <template #cell(rounds)="row">
          {{ row.item.title }}
          <div
            v-if="row.item.status === 'enabled'"
            class="mt-50"
          >
            <small
              :for="`application-link-${row.item.id}`"
            >Application Link</small>
            <b-input-group
              v-if="row.item.status"
            >
              <b-form-input
                :id="`application-link-${row.item.id}`"

                size="sm"
                :value="`${row.index>0?`${baseURL}/startup-portal/applications/rounds/${$route.params.id}/`:`${baseURL}/startup-portal/application/${$route.params.id}/${row.item.id}/`}`"
                @keydown="event => event.preventDefault()"
                @focus="event => {event.target.select()}"
              />
              <template #append>
                <!-- Copy to Clipboard button with Copy icon at start -->
                <b-button
                  size="sm"
                  variant="outline-primary"
                  @click="copyToClipboard(`${row.index>0?`${baseURL}/startup-portal/applications/rounds/${$route.params.id}/`:`${baseURL}/startup-portal/application/${$route.params.id}/${row.item.id}/`}`)"
                >
                  <feather-icon icon="CopyIcon" />
                  Copy
                </b-button>

              </template>
            </b-input-group>
          </div>
        </template>
        <template
          #cell(application)="row"
        >
          <span>
            <feather-icon
              v-b-tooltip="'Applications are '+row.item.status"
              :class="row.item.status==='enabled'?'text-success':'text-danger'"
              class="mr-50"
              fill="currentColor"
              icon="CircleIcon"
            />
          </span>
          <b-button
            :variant="row.item.status === 'enabled' ? 'danger' : 'primary'"
            size="sm"
            @click="updateApplicationStatus(row.item.status, row.item.id, `status`)"
          ><span v-if="row.item.status === 'enabled'">Disable</span><span v-else>Enable</span>
          </b-button>
        </template>
        <template
          #cell(review)="row"
        >
          <span>
            <feather-icon
              v-b-tooltip="'Review is '+row.item.review_status"
              :class="row.item.review_status==='enabled'?'text-success':'text-danger'"
              class="mr-50"
              fill="currentColor"
              icon="CircleIcon"
            />
          </span>
          <b-button
            :variant="row.item.review_status === 'enabled' ? 'danger' : 'primary'"
            size="sm"
            @click="updateApplicationStatus(row.item.review_status, row.item.id, `review_status`)"
          ><span v-if="row.item.review_status === 'enabled'">Disable</span><span v-else>Enable</span>
          </b-button>
        </template>
      </b-table>
    </b-overlay>
    <b-modal
      id="appModal"
      ok-only
      size="lg"
      title="Application Form"
    >
      <form-builder
        v-if="selectedRound !== null"
        v-model="rounds[selectedRound].programs_applicationsquestionstables"
        :inherited-sections="rounds[selectedRound].programs_applicationsquestionstables"
      />
    </b-modal>
  </div>
</template>
<script>
import FormBuilder from '@/views/incubator-portal/programs/components/FormBuilder.vue'
import {
  BButton,
  BModal,
  BTable,
  BFormInput,
  BInputGroup,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
// import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BButton,
    BModal,
    BTable,
    FormBuilder,
    BFormInput,
    BInputGroup,

  },
  data() {
    return {
      mutationLoading: false,
      selectedRound: null,
      pageLength: 5,
      baseURL: window.location.origin,
      filter: {
        round: null,
        status: null,
      },
      columns: [
        {
          label: 'Applicant',
          field(row) {
            if (!row.users_organizationtable) return '-'
            return row.users_organizationtable.title
          },
        },
        {
          id: 'round',
          label: 'Shortlisting Round',
          field(row) {
            if (!row.programs_applicationformstable) return ''
            return row.programs_applicationformstable.title
          },
          filterOptions: {
            enabled: true, // enable filter for this column
            customFilter: true, // use custom filter component
          },
        },
        {
          label: 'Status',
          field: 'status',
          sortable: false,
          filterOptions: {
            enabled: true, // enable filter for this column
            customFilter: true, // use custom filter component
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      rounds: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        draft: 'light-primary',
        enabled: 'light-success',
        disabled: 'light-secondary',
        null: 'light-primary',
        accepted: 'light-success',
        declined: 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    filteredRows() {
      if (!this.filter.round && !this.filter.status) return this.rows
      return this.rows.filter(row => row.programs_applicationformstable)
        .filter(row => (this.filter.round === null || row.programs_applicationformstable.round_number === this.filter.round))
        .filter(row => (this.filter.status === null || !row.programs_applicationformstable.status === this.filter.status || (this.filter.status === 'in-process' && !row.programs_applicationformstable.status)))
    },
    roundFilterOptions() {
      return this.rows.filter(e => e.programs_applicationformstable)
        .sort((a, b) => a.programs_applicationformstable.round_number - b.programs_applicationformstable.round_number)
        .map(row => ({
          text: `Round ${row.programs_applicationformstable.round_number} - ${row.programs_applicationformstable.title}`,
          value: row.programs_applicationformstable.round_number,
        }))
        .filter((e, i, a) => a.findIndex(t => t.value === e.value) === i) // Remove duplicates
    },
  },
  methods: {
    addRound() {
      this.rounds.push({
        title: 'New Round',
        round_number: this.rounds.length + 1,
        status: 'draft',
        review_status: 'draft',
        program_id: this.$route.params.id,
        programs_applicationsquestionstables: [],
      })
    },
    updateApplication(i) {
      const payload = this.rounds[i]

      if (payload.programs_applicationsquestionstables.length) {
        payload.programs_applicationsquestionstables = {
          data: payload.programs_applicationsquestionstables,
          on_conflict: {
            constraint: 'programs_applicationsquestionstable_pkey',
            update_columns: ['question', 'input_type', 'options', 'section'],
          },
        }
      } else {
        delete payload.programs_applicationsquestionstables
      }
      this.mutationLoading = true
      this.$apollo.mutate(({
        mutation: gql`mutation($object: programs_applicationformstable_insert_input!) {
            insert_programs_applicationformstable_one(object: $object, on_conflict: {constraint: programs_applicationformstable_pkey, update_columns: title}) {
              id
            }
          }`,
        variables: {
          object: payload,
        },
        update: () => {
          this.$apollo.queries.rounds.refetch()
          this.mutationLoading = false
        },
      }))
    },
    updateApplicationStatus(status, item, col) {
      const update = status === 'enabled' ? 'disabled' : 'enabled'
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation {
          update_programs_applicationformstable_by_pk(pk_columns: {id: ${item}}, _set: {${col}: ${update}}) {
            id
          }
        }`,
        update: () => {
          this.$apollo.queries.rounds.refetch()
          this.mutationLoading = false
        },
      })
    },
    copyToClipboard(text) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
          this.$bvToast.toast('Application link copied to clipboard', {
            title: 'Success',
            variant: 'success',
            solid: true,
            autoHideDelay: 3000,
          })
        }).catch(() => {
        })
      } else {
        const textarea = document.createElement('textarea')
        textarea.value = text
        textarea.style.position = 'fixed' // ensure the textarea is rendered offscreen
        document.body.appendChild(textarea)
        textarea.focus()
        textarea.select()
        try {
          document.execCommand('copy') // execute the copy command
        } catch (err) {
          // failed to copy
        } finally {
          document.body.removeChild(textarea) // remove the textarea from the DOM
        }
      }
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_applicantstable(where:{program_id: {_eq: ${this.$route.params.id}}}){
            id
            program_id
            status
            current_application_form_id
            users_organizationtable {
              title
            }
            programs_applicationformstable {
              title
              round_number
            }
          }
        }`
      },
      update: data => data.programs_applicantstable,
    },
    rounds: {
      query() {
        return gql`
        {
          programs_applicationformstable(where:{program_id: {_eq: ${this.$route.params.id}}}, order_by: {id: asc}){
            id
            round_number
            title
            status
            review_status
            programs_applicationsquestionstables(order_by: {id: asc}) {
              id
              question
              section
              options
              input_type
              is_required
            }
          }
        }`
      },
      update(data) {
        /* eslint-disable no-param-reassign */
        if (data.programs_applicationformstable.length) {
          data.programs_applicationformstable.forEach(e => {
            if (e.status !== 'draft') {
              delete e.programs_applicationsquestionstables
            }
          })
        }
        return data.programs_applicationformstable
      },
    },
  },
}
</script>

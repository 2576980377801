<template>
  <div>
    <b-overlay
      :show="$apollo.loading"
      rounded="sm"
      spinner-variant="primary"
    >
      <b-card
        class="mt-2"
        style="text-align: left;"
      >
        <div class="custom-search d-flex justify-content-start">
          <b-form-group
            class="form-inline"
            label="Search"
            label-size="sm"
          >
            <b-form-input
              v-model="searchTerm"
              class="d-inline-block mr-1"
              placeholder="Search exercises"
              type="text"
            />
            <b-button
              variant="primary"
              @click="$bvModal.show('addparticipantmodal')"
            >
              <feather-icon
                class="mr-25"
                icon="PlusIcon"
              />
              Add Participant
            </b-button>
          </b-form-group>
        </div>
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage:pageLength,
            position: 'top',
          }"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Action -->
            <span
              v-if="props.column.id === 'round' && props.row.programs_applicationformstable"
            ><b-badge
               v-b-tooltip="'Round Number'"
               class="mr-50 mb-25"
               variant="primary"
             >{{ props.row.programs_applicationformstable.round_number }}</b-badge>
              {{ props.formattedRow[props.column.field] }}
            </span>

            <!-- Column: Status-->
            <span
              v-else-if="props.column.field === 'organization_name' && props.row.programs_applicantstable.users_organizationtable"
              style="display: flex; justify-content: space-between;"
            >
              <span>
                {{ props.row.programs_applicantstable.users_organizationtable.title }}
              </span>
              <b-badge
                variant="light-primary"
              >
                {{ props.row.programs_applicantstable.users_organizationtable.type }}
              </b-badge>
            </span>

            <span
              v-else-if="props.column.field === 'organization_name' && !props.row.programs_applicantstable.users_organizationtable"
              style="display: flex; justify-content: flex-end;"
            >
              <b-badge
                variant="light-primary"
              >
                {{ props.row.type }}
              </b-badge>
            </span>

            <span v-if="props.column.field === 'status'">
              <!--              <b-form-input-->
              <!--                v-model="props.columnFilters.status"-->
              <!--                placeholder="Filter by Status"-->
              <!--                @input="props.columnFiltersChanged"-->
              <!--              />-->
              <b-badge
                :variant="props.row.is_active?'light-success':'light-danger'"
                class="text-capitalize mr-1"
              >
                {{ props.row.is_active?"Active":"Inactive" }}
              </b-badge>
              <b-button
                :variant="props.row.is_active?'outline-danger':'primary'"
                size="sm"
                @click="updateStatus(props.row.id, !props.row.is_active)"
              >
                {{ props.row.is_active?"Deactivate":"Activate" }}
              </b-button>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <!-- <b-button
                    :to="{name: 'events-view-applications', params: {pid: $route.params.id,aid:props.row.id }}"
                    size="sm"
                    variant="outline-primary"
                  >View Applications</b-button> -->
              <b-button
                size="sm"
                variant="flat-danger"
                class="text-capitalize"
                @click="$bvModal.show('deleteModal'); selectedRow = props.row.id;"
              >
                <feather-icon
                  icon="TrashIcon"
                />
              </b-button>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <!-- pagination -->
          <template
            slot="pagination-top"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mb-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10','20','30']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of <span class="font-medium-4 ml-25"><b-badge
                  variant="light-primary"
                >{{
                  props.total
                }} entries
                </b-badge></span></span>

              </div>
              <div>
                <b-pagination
                  :per-page="pageLength"
                  :total-rows="props.total"
                  :value="1"
                  align="right"
                  class="mt-50"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
      <b-modal
        id="addparticipantmodal"
        size="lg"
        title="Add Participant"
        ok-title="Promote"
        no-close-on-backdrop
        @ok="promoteParticipants"
      >
        <b-row>
          <b-col>
            <b-form-group
              style="margin-top: 0.1rem;"
              label="Select Application Round"
              label-size="sm"
            >
              <treeselect
                v-model="applicationround"
                style="margin-top: 0.4rem;"
                :normalizer="normalizer"
                :options="applicationrounds"
                :get-option-label="option => option.title"
                :reduce="option => option.id"
                placeholder="Choose application round"
                @select="checkApplication"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              style="margin-top: 0.1rem;"
              label="Select Applicant Startup"
              label-size="sm"
            >
              <treeselect
                v-model="selectedstartup"
                style="margin-top: 0.4rem;"
                :normalizer="normalizer2"
                :options="startupsdata"
                :reduce="option => option.id"
                placeholder="Choose Startup"
                :multiple="true"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
        id="deleteModal"
        ok-title="Yes, Delete"
        ok-variant="danger"
        button-size="sm"
        no-close-on-backdrop
        centered="true"
        size="sm"
        title="Confirm Delete"
        @ok="deleteMentor()"
      >
        <h6>Are you sure you want to delete this mentor?</h6>
      </b-modal>
      <b-modal
        id="assignStartup"
        ok-only
        ok-title="Assign"
        title="Assign Startup"
        no-close-on-backdrop
        @ok="assignStartup"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Select Startups"
              label-for="selectStartups"
            >
              <v-select
                id="selectStartups"
                v-model="startupParticipant"
                :get-option-label="option => option.programs_applicantstable.users_organizationtable.title"
                :options="startups"
                :reduce="option => option.id"
                placeholder="Select Startups"
              />
              <vue-good-table
                :columns="columns2"
                :pagination-options="{
                  enabled: true,
                  perPage:pageLength,
                  position: 'top',
                }"
                :rows="startups2"
                :search-options="{
                  enabled: true,
                  externalQuery: searchTerm }"
              >
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.formattedRow[props.column.field].field === 'action'">
                    <b-button
                      variant="danger"
                      size="sm"
                    >Deactivate
                    </b-button>
                  </span>

                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
                <!-- pagination -->
                <template
                  slot="pagination-top"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mb-1">
                      <span class="text-nowrap ">
                        Showing 1 to
                      </span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['3','5','10']"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                      />
                      <span class="text-nowrap"> of <span class="font-medium-4 ml-25"><b-badge
                        variant="light-primary"
                      >{{
                        props.total
                      }} entries
                      </b-badge></span></span>

                    </div>
                    <div>
                      <b-pagination
                        :per-page="pageLength"
                        :total-rows="props.total"
                        :value="1"
                        align="right"
                        class="mt-50"
                        first-number
                        last-number
                        next-class="next-item"
                        prev-class="prev-item"
                        @input="(value)=>props.pageChanged({currentPage:value})"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
    </b-overlay>
  </div>
</template>
<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  BFormSelect,
  BPagination,
  BRow,
  BModal,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BFormInput,
    BCard,
    BCol,
    BFormGroup,
    // BFormCheckbox,
    BRow,
    BBadge,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BModal,
    Treeselect,
  },
  data() {
    return {
      operations: [],
      mutationLoading: false,
      pageLength: 20,
      options: [],
      mentor: {
        id: null,
        email: null,
        designation: null,
      },
      searchData: null,
      selectedfid: null,
      selectedOperation: null,
      applicationround: null,
      selectedstartup: null,
      searchTerm: '',
      rows: [],
      applicationrounds: [],
      startupsdata: [],
      startups: [],
      startups2: [],
      columns: [
        {
          label: 'Full Name',
          field(row) {
            if (!row.programs_applicantstable.users_customuser) return '-'
            return row.programs_applicantstable.users_customuser.full_name
          },
        },
        {
          label: 'Organization Name',
          field: 'organization_name',
        },
        {
          label: 'Status',
          field: 'status',
          sortable: false,
          filterOptions: {
            enabled: true, // enable filter for this column
            customFilter: true,

          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        accept: 'light-success',
        invited: 'light-primary',
        reject: 'light-danger',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
    existingUser() {
      return this.rows.findIndex(e => e.user_id === this.mentor.id) !== -1
    },
  },
  methods: {
    customStatusFilter(value, searchValue) {
    // Implement your custom filter logic here
    // Return true if the value matches the searchValue, otherwise return false
      return value.toLowerCase().includes(searchValue.toLowerCase())
    },
    checkApplication(e) {
      this.startupsdata = e.programs_applicantstables
      // console.log(this.startupsdata)
    },
    normalizer(node) {
      return {
        id: node.id,
        label: `${node.title}`,
        children: node.programs_operationstables,
      }
    },
    normalizer2(node) {
      return {
        id: node.id,
        label: `${node.users_customuser ? node.users_customuser.full_name : node.users_organizationtable.title} ${!node.users_customuser ? `- ${node.users_organizationtable.type}` : ''}`,
        children: node.programs_operationstables,
      }
    },
    addReview() {
      this.mutationLoading = true
      this.$apollo.mutate(
        {
          mutation: gql`mutation($data: [programs_assignmentquestionstable_insert_input!]!) {
                        insert_programs_assignmenttable_one(object: {title: "${this.reviewTitle}",type: "${this.reviewType}", status: "draft", review_status: "draft", operations_id: ${this.selectedOperation}, programs_assignmentquestionstables: {data: $data}}) {
                          id
                        }
                      }`,
          variables: {
            data: this.applicationForm,
          },
          update: (store, { data: { insert_programs_assignmenttable_one } }) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: insert_programs_assignmenttable_one.id ? 'Review added successfully' : 'Failed to create review',
                icon: insert_programs_assignmenttable_one.id ? 'CheckIcon' : 'XIcon',
                variant: insert_programs_assignmenttable_one.id ? 'success' : 'danger',
              },
            })
            this.$apollo.queries.operations.refetch()
            this.mutationLoading = false
          },
        },
      )
      this.reviewTitle = null
      this.selectedOperation = null
      this.applicationForm = null
      this.reviewType = null
    },
    promoteParticipants() {
      const startupParticipantsData = this.selectedstartup.map(sdata => ({
        applicant_id: sdata,
        program_id: this.$route.params.id,
      }))
      this.mutationLoading = true
      this.$apollo.mutate(
        {
          mutation: gql`mutation insertStartupParticipants($data: [programs_startupparticipants_insert_input!]!) {
                        insert_programs_startupparticipants(objects: $data) {
                          affected_rows
                        }
                      }`,
          variables: {
            data: startupParticipantsData,
          },
          update: (store, { data: { insert_programs_startupparticipants } }) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: insert_programs_startupparticipants.affected_rows > 0 ? 'Participants added successfully' : 'Failed to add participants',
                icon: insert_programs_startupparticipants.affected_rows > 0 ? 'CheckIcon' : 'XIcon',
                variant: insert_programs_startupparticipants.affected_rows > 0 ? 'success' : 'danger',
              },
            })
            this.$apollo.queries.operations.refetch()
            this.$apollo.queries.rows.refetch()
            this.mutationLoading = false
          },
        },
      )
      this.reviewTitle = null
      this.selectedOperation = null
      this.applicationForm = null
      this.reviewType = null
    },
    updateAssignmentStatus(status, item, col) {
      const update = status === 'enabled' ? 'disabled' : 'enabled'
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation {
          update_programs_assignmenttable_by_pk(pk_columns: {id: ${item}}, _set: {${col}: ${update}}) {
            id
          }
        }`,
        update: () => {
          this.$apollo.queries.operations.refetch()
          this.mutationLoading = false
        },
      })
    },
    fetchUser() {
      if (!this.mentor.email) return false
      this.mutationLoading = true
      this.$apollo.query({
        query: gql`query options($q: String!){
              users_customuser(where: {email: {_ilike: $q}}) {
                      full_name
                      email
                      id
                    }
              }`,
        variables: { q: this.mentor.email },
      })
        .then(({ data }) => {
          this.mutationLoading = false
          this.searchData = !data.users_customuser[0] ? false : data.users_customuser[0].full_name
          this.mentor.id = data.users_customuser[0]?.id || null
        })
      return true
    },
    addMentor() {
      this.$apollo.mutate({
        mutation: gql`
          mutation {
            insert_users_associationtable_one(object: {program_id: ${this.$route.params.id}, role: "${this.mentor.designation}", status: "Invited", is_participant: true, user_id: ${this.mentor.id}}) {
              id
            }
          }`,
        update: () => {
          this.mentor.id = null
          this.mentor.designation = null
          this.$apollo.queries.rows.refetch()
        },
      })
    },
    getStartups() {
      this.$apollo.queries.startups.refetch()
      this.$apollo.queries.startups2.refetch()
    },
    assignStartup() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          insert_programs_startupassignmenttable_one(object: {participant_id: "${this.startupParticipant}", partner_id: "${this.selectedRow}"}) {
            id
          }
        }`,
        update: (store, { data: { insert_programs_startupassignmenttable_one } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_programs_startupassignmenttable_one.id ? 'Added successfully' : 'Failed to add',
              icon: insert_programs_startupassignmenttable_one.id ? 'CheckIcon' : 'XIcon',
              variant: insert_programs_startupassignmenttable_one.id ? 'success' : 'warning',
            },
          })
          this.$apollo.queries.startups.refetch()
          this.$apollo.queries.startups2.refetch()
          this.mutationLoading = false
        },
      })
    },
    updateStatus(item, status) {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation {
          update_programs_startupparticipants_by_pk(pk_columns: {id: ${item}}, _set: {is_active: ${status}}) {
            id
          }
        }`,
        update: () => {
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    deleteMentor() {
      const item = this.selectedRow
      this.mutationLoading = true
      if (item) {
        this.$apollo.mutate({
          mutation: gql`mutation {
            update_programs_startupparticipants_by_pk(pk_columns: {id: ${item}}, _set: {is_deleted: true}) {
              id
            }
          }`,
          update: () => {
            this.$apollo.queries.rows.refetch()
            this.mutationLoading = false
          },
        })
      }
    },
  },
  apollo: {
    applicationrounds: {
      query() {
        return gql`{
          programs_applicationformstable(where: {program_id: {_eq: ${this.$route.params.id}}}, order_by: {id: asc}) {
            id
            title
            programs_applicantstables(where: {programs_startupparticipants_aggregate: {count: {predicate: {_eq: 0}}}}) {
              id
              type
              users_customuser {
                full_name
              }
              users_organizationtable {
                title
                type
              }
            }
          }
        }`
      },
      update: data => data.programs_applicationformstable,
    },
    operations: {
      query() {
        return gql`{
          programs_operationstable(order_by: {id: desc}, where: {program_id: {_eq: 183} ${this.$route.params.wid ? `, id: {_eq: ${this.$route.params.wid}}` : ''}}) {
            title
            id
            programs_assignmenttables {
              id
              status
              review_status
              title
            }
            operations_type
            programs_operationstable {
              id
              operations_type
            }
          }
        }`
      },
      update: data => data.programs_operationstable,
    },
    rows: {
      query() {
        return gql`
        {
          programs_startupparticipants(where: {program_id: {_eq: ${this.$route.params.id}}}) {
            id
            is_active
            programs_applicantstable {
              users_organizationtable {
                title
                type
              }
              users_customuser {
                full_name
              }
            }
          }
        }`
      },
      update: data => data.programs_startupparticipants,
    },
    startups: {
      query() {
        return gql`{
          programs_startupparticipants(where: {programs_applicantstable: {program_id: {_eq: ${this.$route.params.id}}}, is_active: {_eq: true}, programs_startupassignmenttables_aggregate: {count: {predicate: {_eq: 0}, filter: {partner_id: {_eq: ${this.selectedRow}}}}}}) {
            id
            programs_applicantstable {
              users_organizationtable {
                title
              }
            }
          }
        }`
      },
      update: data => data.programs_startupparticipants,
    },
    startups2: {
      query() {
        return gql`{
          programs_startupassignmenttable(where: {partner_id: {_eq: ${this.selectedRow}}}) {
            participant_id
            partner_id
            programs_startupparticipant {
              programs_applicantstable {
                users_organizationtable {
                  title
                }
              }
            }
          }
        }`
      },
      update: data => data.programs_startupassignmenttable,
    },
  },
}
</script>
